import React, { Component } from 'react';
import SignIn from './SignIn'
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import MapContainer from './map/MapContainer'
import MapHome from './map/MapHome'

import Button from '@material-ui/core/Button';
function App() {
  console.log('App is initializing ');
  return (
    <ThemeProvider theme={theme}>
      <MapHome/>
    </ThemeProvider>
  );
}

export default App;
