import React,{ useEffect }  from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux'
import { dispatch } from 'rxjs/internal/observable/pairs';
import CircularProgress from '@material-ui/core/CircularProgress';
import Utilities from '../utils/Utilities'
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import {CarwashActions} from '../actions';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';

// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';

const days = [
  {
    value: 'sun-sat',
    label: 'Sun - Sat',
  },
  {
    value: 'mon-fri',
    label: 'Mon - Fri',
  },
  {
    value: 'mon',
    label: 'Monday',
  },
  {
    value: 'tue',
    label: 'Tuesday',
  },
  {
    value: 'wed',
    label: 'Wednesday',
  },
  {
    value: 'thu',
    label: 'Thursday',
  },
  {
    value: 'fri',
    label: 'Friday',
  },
  {
    value: 'sat',
    label: 'Saturday',
  },
  {
    value: 'sun',
    label: 'Sunday',
  },
];

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
      spacing:(2)
    },
  },
  textField: {
    marginRight: theme.spacing(1),
    width: 200,
  },
  textFieldRadius: {
    width: 25,
  },
  timePicker:{
    marginTop:0,
    width:140,
  },
  pricingAddButton: {
    marginTop: theme.spacing(2),
    margingRight:theme.spacing(1)
  },
  textField300: {
    marginRight: theme.spacing(1),
    width: 300,
  },
  textField150: {
    marginRight: theme.spacing(1),
    width: 150,
  },
  paperContainer :{
    margin : theme.spacing(1)
  },
  margin: {
    margin: theme.spacing(1),
  },
  
  textFieldDropdown: {
    marginRight:20,
    flexBasis: 200,
  },
  note: {
    flex: 1,
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin : theme.spacing(1),
  }
}));

const carwashTypeMap = {
  'dt':1,
  'hw' : 2,
  'ss' : 3,
  'mobile' : 4,
  'dtbrsh' : 5,
  'dtnotouch' : 6,
  'dtnobrsh': 7
}

function AddCarWashDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // const [carwashSuggestion,setCarwashSuggestion] =  React.useState(props.carwashSuggestion);
  const [name, setName] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [statusMsg, setStatusMsg] = React.useState(null);
  const [pricingArr,setPricingArr] = React.useState(new Array());
  var [carwashFields, setCarwashFields] = React.useState({
    "name" : getCarWashField("name",props.carwashSuggestion),
    "address.line1" : getCarWashField("address.line1",props.carwashSuggestion),
    "address.line2" : getCarWashField("address.line2",props.carwashSuggestion),
    "address.city" : getCarWashField("address.city",props.carwashSuggestion),
    "address.state" : getCarWashField("address.state",props.carwashSuggestion),
    "address.zip" : getCarWashField("address.zip",props.carwashSuggestion),
    "phone.number" : getCarWashField("phone.number",props.carwashSuggestion),
    "services.gas" : getCarWashFieldAsBool("options.gas",props.carwashSuggestion),
    "services.charge" : getCarWashFieldAsBool("options.charge",props.carwashSuggestion),
    "services.store" : getCarWashFieldAsBool("options.store",props.carwashSuggestion),
    "services.vaccum" : getCarWashFieldAsBool("options.vaccum",props.carwashSuggestion),
    "services.air" : getCarWashFieldAsBool("options.air",props.carwashSuggestion),
    "services.mshp" : getCarWashFieldAsBool("options.mshp",props.carwashSuggestion),
    "services.ecofr" : getCarWashFieldAsBool("options.ecofr",props.carwashSuggestion),
    "services.dtlng" : getCarWashFieldAsBool("options.dtlng",props.carwashSuggestion),
  });
var [operatingHrArr,setOperatingHrArr] = React.useState([]);
const [carwashType,setCarwashType] = React.useState(getCarwashTypeFromSuggestion(props.carwashSuggestion));
const [refresh,setRefresh] = React.useState(false);
var [priceArrIdx,setPriceArrayIdx] = React.useState(0);
var [operatingHrArrIdx,setOperatingHrArrIdx] = React.useState(0);
const initialState = {"a" : ""}

const [errorStatus,setErrorStatus] = React.useState({"name" : false,
  "commEmail" : false,
  "contactPhone" : false,
  "slotID" : false,
});

useEffect(() => {
  props.resetCarwashAddStates();

  setOperatingHourArrayFromSuggetion(props.carwashSuggestion);
  setPricingArrayFromSuggestion(props.carwashSuggestion);
},[]);


function getCarWashFieldAsBool(fieldName,item){
  let val = getCarWashField(fieldName,item);
  if(!val){
    return false;
  }
  return val;
}

function getCarWashField(fieldName, item){
  console.log('getting appointment field ',fieldName);
  if(fieldName === 'options'){
    getOptionsFromSuggestionRecord();
    return;
  }
  if(item){
    let value = null;
    if(fieldName.indexOf('.') != -1){
      let keys = fieldName.split('.');
      let iterLen = keys.length;
      let obj = item[keys[0]]; 
      console.log('got first level obj ',obj);
      for (let i = 1 ; i < iterLen; i++){
        if(obj != null){
          obj = obj[keys[i]]
          console.log('got '+i+' level obj ',obj);
          if(i == iterLen - 1){
            value = obj;
          }
        }
      }
    }
    else {
      value = item[fieldName]
    }
    console.log('Field for key '+fieldName+'is ',item[fieldName]);
    return value;
  }
  return null;
}

function getOptionsFromSuggestionRecord(item){
  let options = item["options"];
  if(options){

  } 
}


function proceedToAddCarwash(){
    //collect the details and send the create appointment request
  //  var params = {
  //   "name": "Fast Furious Wash",
  //   "address": {
  //     "line1": "5700, Westpark drive",
  //     "line2": "Suite A530",
  //     "city": "McLean",
  //     "zip": "21022",
  //     "country": "US"
  //   },
  //   "coordinates": [38.927543,-77.222096],
  //   "serviceArea": {
  //     "type": "Polygon",
  //     "coordinates": [
  //       [
  //         [0, 0],
  //         [3, 6],
  //         [6, 1],
  //         [0, 0]
  //       ],
  //       [
  //         [2, 2],
  //         [3, 3],
  //         [4, 2],
  //         [2, 2]
  //       ]
  //     ]
  //   },
  //   "phone": {
  //     "areaCode": "703",
  //     "number": "7032345678"
  //   },
  //   "pricing": [{
  //     "title": "Car wash",
  //     "desc": "Car wash automated by sprinklers",
  //     "value": 30
  //   }],
  //   "type": {
  //     "dt": true,
  //     "hw": true,
  //     "ss": true,
  //     "mobile": true
  //   },
  //   "options": {
  //     "gas": true,
  //     "charge": true,
  //     "store": true,
  //     "vaccum": true,
  //     "air": true,
  //     "mshp": true
  //   },
  //   "hours": [{
  //     "day": 0,
  //     "start": "09:30",
  //     "end": "17:30"
  //   }]
  // }
  var postJSON = prepareAddCarwashPayload();
  console.log('JSON for posting is :: ',postJSON);
  if(postJSON){
    addCarwash(postJSON);
  }
}

  const addCarwash = (carwashParams) => props.addCarwash(carwashParams);
  const resetCarWashAddStates = () => props.resetCarwashAddStates();

  function getCarWashFieldDict(fieldName,item){
    if(item){
      console.log('Field for key '+fieldName+'is ',item[fieldName]);
      return item[fieldName];
    }
    return {};
  }
  
  
  function onNegativeAction(title) {
    resetCarWashAddStates();
    props.onDialogAction('cancel',null)
  }

  function onClearFormAction(){
    resetCarWashAddStates()
    props.resetGeocodeResults();
    console.log('onClearFormAction ENTRY');
    //deep copy objects
    let fields = JSON.parse(JSON.stringify(carwashFields));
    fields['name'] = ''
    fields['address.line1'] = ''
    fields['address.line2'] = ''
    fields['address.zip'] = ''
    fields['address.state'] = ''
    fields['address.city'] = ''
    fields['phone.areaCode'] = ''
    fields['phone.number'] = ''
    fields['coordinates'] = ''

    fields['services.gas'] = false
    fields['services.charge'] = false
    fields['services.store'] = false
    fields['services.vaccum'] = false
    fields['services.air'] = false
    fields['services.mshp'] = false
    fields['services.ecofr'] = false
    fields['services.dtlng'] = false
    
    console.log('fields being set ',fields);
    
    for (var i = 0; i < priceArrIdx; i++){
      var pricingId = "pricing"+i ;
      var name = pricingId+"_name";
      console.log('name into the dict ',name);
      fields[name] = '';
      var desc = pricingId+"_desc";
      fields[desc] = '';
      var price = pricingId+"_price";
      fields[price] = '';
    }
    console.log('Fields updated for pricing ',fields);
    console.log('Car wash fields after setting',carwashFields);
    for (var i = 0; i < operatingHrArrIdx; i++){
      var day = 'operatingHr'+i+"_day"
      var closed = 'operatingHr'+i+"_closed";
      var hrs24 = 'operatingHr'+i+"_24hrs";
      var startDate = 'operatingHr'+i+"_start";
      var endDate = 'operatingHr'+i+"_end";
      fields[day] = null;
      fields[closed] = false;
      fields[hrs24] = false;
      fields[startDate] = new Date();
      fields[endDate] = new Date();
    }

    setCarwashFields(fields);
    
    setCarwashType('dt');
    setPricingArr(new Array());
    setPriceArrayIdx(0);
    setOperatingHrArr(new Array())
    setOperatingHrArrIdx(0)
    console.log('fields after resetting ',carwashFields);
  }

  const handleDateChange = prop => date => {
    console.log('handleDateChange ENTRY ',prop);
    console.log('handleDateChange date ',date);
    setCarwashFields({ ...carwashFields, [prop]: date });
    console.log('Updated carwash  fields' ,carwashFields);
  };

  function onCarwashAddSuccessDialogDismiss(){
    props.onDialogAction('CarWashAddSuccessDismiss');
  }

  function onCarwashAddErrorDialogDismiss(){
    console.log('dismissing car wash add error');
  }

  function onPositiveAction() {
    console.log('Create appointment positive action : ',carwashFields);
    proceedToAddCarwash();
  }

  function prepareAddCarwashPayload(){
    //main JSON 
    var mainJSON = {};

    //validate the mandatory fields 
    var name = carwashFields["name"];
    var addressLine1 = carwashFields["address.line1"];
    var city = carwashFields["address.city"];
    var zip = carwashFields["address.zip"];
    var state = carwashFields["address.state"];
    var missingFields = new Array();
    var updated = {...errorStatus};
  
    if(name == null || name.length == 0){
      updated["name"] = true;
      missingFields.push("name");
    }
    if(addressLine1 == null || addressLine1.length == 0){
      updated["address.line1"] = true;
      missingFields.push("address.line1");
    }
    if(city == null || city.length == 0){
      updated["address.city"] = true;
      missingFields.push("address.city");
    }
    if(zip == null || zip.length == 0){
      updated["address.zip"] = true;
      missingFields.push("address.zip");
    }
    if(state == null || state.length == 0){
      updated["address.state"] = true;
      missingFields.push("address.state");
    }
    mainJSON['name'] = name;

    //address fields
    var addressJSON = {};
    addressJSON["line1"] = addressLine1;
    addressJSON["line2"] = carwashFields["address.line2"];
    addressJSON["city"] = city;
    addressJSON["zip"] = zip;
    let stateVal = Utilities.getStateAbbrevationMapping(state);
    if (stateVal == null){
      stateVal = state;
    }
    addressJSON["state"] = stateVal;
    mainJSON["address"] = addressJSON;

    //phone fields 
    // var phoneJSON = {};
    // phoneJSON["area"] = carwashFields["phone.areaCode"];
    // phoneJSON["number"] = carwashFields["phone.number"];
    mainJSON["phone"] = carwashFields["phone.number"];
    
    //coordinates 
    var value = carwashFields["coordinates"];
    console.log('Coordinate value is ',value);
    console.log('geocode result',props.geoCodeLatLng);

    if(!value || value.length == 0 || value.split(',').length != 2){
      if(props.geoCodeLatLng){
        value = props.geoCodeLatLng.join(',');
        setCarwashFields({ ...carwashFields, 'coordinates': value });
      }
      console.log('Value after geocoding ',value);
    }
    if(!value || value.length == 0 || value.split(',').length != 2){
      // updated["coordinates"] = true;
      // missingFields.push("coordinates");
    }
    else {
      var splits = value.split(',');
      var coordArr = new Array();
      coordArr.push(parseFloat(splits[1]));
      coordArr.push(parseFloat(splits[0]));
      mainJSON['coordinates'] = coordArr;  
    }

    //dt = 1
    //hw = 2
    //ss = 3
    //modible = 4
    //dtbrsh = 5
    //dtnotouch = 6
    //dtnobrsh = 7

    //type TBD 
    console.log('carwashType value is >>',carwashType);
    let type = getCarwashTypeFromString(carwashType);
    mainJSON["type"] = type;
    let radius = -1;
    console.log('Carwash type is ',type);
    if (type == 4){
      radius = carwashFields["radius"];
      console.log('Radius value is ',radius);
      if (!radius || radius.length == 0){
        updated["radius"] = true;
        missingFields.push("radius");
      }
      else {
        mainJSON["radius"] = parseInt(radius);
      }
      let ph = carwashFields["phone.number"];
      if (!ph || ph.length == 0){
        updated["phone.number"] = true;
        missingFields.push("phone.number");
      }
    }

    //options JSON 
    var optionsJSON = {};
    optionsJSON["gas"] = carwashFields["services.gas"] == true ? true : false;
    optionsJSON["charge"] = carwashFields["services.charge"] == true ? true : false;
    optionsJSON["store"] = carwashFields["services.store"] == true ? true : false;
    optionsJSON["vaccum"] = carwashFields["services.vaccum"] == true ? true : false;
    optionsJSON["air"] = carwashFields["services.air"] == true ? true : false;
    optionsJSON["mshp"] = carwashFields["services.mshp"] == true ? true : false;
    optionsJSON["ecofr"] = carwashFields["services.ecofr"] == true ? true : false;
    optionsJSON["dtlng"] = carwashFields["services.dtlng"] == true ? true : false;
    mainJSON["options"] = optionsJSON;

    //pricing array 
    var pricingArr = new Array();
    for (var i = 0; i < priceArrIdx; i++){
      var pName = carwashFields['pricing'+i+"_name"];
      var pDesc = carwashFields['pricing'+i+"_desc"];
      var pPrice = carwashFields['pricing'+i+"_price"];
      if(pName && pDesc && pPrice){
        var pDict = {};
        pDict["title"] = pName;
        pDict["desc"] = pDesc;
        pDict["value"] = pPrice;
        pricingArr.push(pDict);
      }
    }
    if(pricingArr.length > 0){
      mainJSON["pricing"] = pricingArr;
    }

    var hasWeekThroughChoiceIdx = -1;
    var hasWeekDaysThroughChoiceIdx = -1;
    var satChoiceIdx = -1;
    var sunChoiceIdx = -1;
    console.log('Carwash fields just before preparing array is ',carwashFields);
    for (var i = 0; i < operatingHrArrIdx; i++){
      var day = carwashFields['operatingHr'+i+"_day"];
      if(day == 'sun-sat'){
        hasWeekThroughChoiceIdx = i;
      }
      else if (day == 'mon-fri'){
        hasWeekDaysThroughChoiceIdx = i;
      }
      else if (day == 'sat'){
        satChoiceIdx = i;
      }
      else if (day == 'sun'){
        sunChoiceIdx = i;
      }
    }
    var operatingHrs = new Array();

    if(hasWeekThroughChoiceIdx != -1){
      console.log('Entering into hasWeekThroughChoiceIdx', carwashFields);
      console.log('hasWeekDaysThroughChoiceIdx ',hasWeekThroughChoiceIdx);
      var closed = carwashFields['operatingHr'+ hasWeekThroughChoiceIdx +"_closed"];
      var hrs24 = carwashFields['operatingHr'+  hasWeekThroughChoiceIdx +"_24hrs"];
      var startDate = carwashFields['operatingHr'+ hasWeekThroughChoiceIdx +"_start"];
      var endDate = carwashFields['operatingHr'+ hasWeekThroughChoiceIdx +"_end"];

      for (var i = 2 ; i < days.length; i++){
        var pDict = {};
        pDict["day"] = days[i].value;
        pDict["closed"] = closed;
        pDict["hrs24"] = hrs24;
        var startTime = '0:0';
        var endTime = '0:0';
        if(startDate){
          startTime = startDate.getHours()+':'+startDate.getMinutes();
        }
        if (endDate) {
          endTime = endDate.getHours()+':'+endDate.getMinutes();
        }
        console.log('start Time',startTime);
        console.log('end Time',endTime);
        pDict["start"] = startTime;
        pDict["end"] = endTime;
        console.log('pDict being pushed hasWeekThroughChoiceIdx',pDict);
        operatingHrs.push(pDict);
      }
    }
    else if (hasWeekDaysThroughChoiceIdx != -1){
      console.log('Entering into hasWeekDaysThroughChoiceIdx');
      var closed = carwashFields['operatingHr'+ hasWeekDaysThroughChoiceIdx +"_closed"];
      var hrs24 = carwashFields['operatingHr'+  hasWeekDaysThroughChoiceIdx +"_24hrs"];
      var startDate = carwashFields['operatingHr'+ hasWeekDaysThroughChoiceIdx +"_start"];
      var endDate = carwashFields['operatingHr'+ hasWeekDaysThroughChoiceIdx +"_end"];

      for (var i = 2 ; i < days.length - 2 ; i++){
        var pDict = {};
        pDict["day"] = days[i].value;
        pDict["closed"] = closed;
        pDict["hrs24"] = hrs24;
        var startTime = '0:0';
        var endTime = '0:0';
        if(startDate){
          startTime = startDate.getHours()+':'+startDate.getMinutes();
        }
        if (endDate) {
          endTime = endDate.getHours()+':'+endDate.getMinutes();
        }
        console.log('start Time',startTime);
        console.log('end Time',endTime);
        pDict["start"] = startTime;
        pDict["end"] = endTime;
        operatingHrs.push(pDict);
      }
      //now check for sunday and saturday explicity 
      var satSunIdx = [satChoiceIdx,sunChoiceIdx];
      var satSunDayIds = ['sat','sun'];
      for (var i = 0; i < satSunIdx.length; i++){
        var idx = satSunIdx[i];
        if(idx == -1) {
          continue;
        }
        var closed = carwashFields['operatingHr'+ idx +"_closed"];
        var hrs24 = carwashFields['operatingHr'+  idx +"_24hrs"];
        var startDate = carwashFields['operatingHr'+ idx +"_start"];
        var endDate = carwashFields['operatingHr'+ idx +"_end"];

        var pDict = {};
        pDict["day"] = satSunDayIds[i];
        pDict["closed"] = closed;
        pDict["hrs24"] = hrs24;
        var startTime = '0:0';
        var endTime = '0:0';
        if(startDate){
          startTime = startDate.getHours()+':'+startDate.getMinutes();
        }
        if (endDate) {
          endTime = endDate.getHours()+':'+endDate.getMinutes();
        }
        console.log('start Time',startTime);
        console.log('end Time',endTime);
        pDict["start"] = startTime;
        pDict["end"] = endTime;
        operatingHrs.push(pDict);
      }
    }

    else{
      //operating hour array 
      for (var i = 0; i < operatingHrArrIdx; i++){
        var day = carwashFields['operatingHr'+i+"_day"];
        var closed = carwashFields['operatingHr'+i+"_closed"];
        var hrs24 = carwashFields['operatingHr'+i+"_24hrs"];
        var startDate = carwashFields['operatingHr'+i+"_start"];
        var endDate = carwashFields['operatingHr'+i+"_end"];
        if(day){
          var pDict = {};
          pDict["day"] = day;
          pDict["closed"] = closed;
          pDict["hrs24"] = hrs24;
          var startTime = '0:0';
          var endTime = '0:0';
          if(startDate){
            startTime = startDate.getHours()+':'+startDate.getMinutes();
          }
          if (endDate) {
            endTime = endDate.getHours()+':'+endDate.getMinutes();
          }
          console.log('start Time',startTime);
          console.log('end Time',endTime);
        
          pDict["start"] = startTime;
          pDict["end"] = endTime;
          operatingHrs.push(pDict);
        }
      }
    }
    
    if(operatingHrs.length > 0){
      mainJSON["hours"] = operatingHrs;
    }

    console.log('Main Hours now is ',mainJSON);
    console.log('Missing fields are ',missingFields);
    if(missingFields.length > 0){
      setErrorStatus(updated);
      return null;
    }
    return mainJSON;
  }

  function getCarwashTypeFromString(type){
    let typeVal = 1;
    typeVal = carwashTypeMap[type];
    if (!typeVal){
      typeVal = 1;
    }
    return typeVal;
  }

  function setPricingArrayFromSuggestion(item){
    // let arr = new Array();
    // let pricing = item['pricing'];

    // var pricingId = "pricing"+priceArrIdx;
    // var name = pricingId+"_name";
    // var desc = pricingId+"_desc";
    // var price = pricingId+"_price";
    
    // arr.push({"id": pricingId, name:"",desc : "" , price : 0.0});
    // console.log('Total number of pricing arrays ',arr);
    // var pIdx = parseInt(priceArrIdx) + 1
    // console.log('New Index is ',pIdx);
    // setPricingArr(arr);
    // setPriceArrayIdx(pIdx);
  }

  function setOperatingHourArrayFromSuggetion(item){
    console.log('== setOperatingHourArrayFromSuggetion ENTRY == ');
    if(!item){
      return;
    }
    var arr = new Array();
    let hours = item['hours'];
    console.log('Operating hours from suggestion ',hours);
    let opIdx = 0;
    var updated = {...carwashFields};
    if (hours){
      let iterLen = hours.length;
      for (let i = 0; i < iterLen; i++){

        let hrInfo = hours[i];
        let dayVal = hrInfo['day'];
        let hrs24Val = hrInfo['hrs24'];
        let closedVal = hrInfo['closed'];
        //this values need to be date instances
        let endVal = hrInfo['end'];
        if(endVal){
          let date = new Date();
          let splits = endVal.split(':');
          if (splits[0]){
            let hr = parseInt(splits[0]);
            date.setHours(hr);
          }
          if (splits[1]){
            let mins = parseInt(splits[1]);
            date.setMinutes(mins);
          }
          endVal = date;
        }
        let startVal = hrInfo['start'];
        if(startVal){
          let date = new Date();
          let splits = startVal.split(':');
          console.log('start split ',splits)
          if (splits[0]){
            let hr = parseInt(splits[0]);
            console.log('setting hours as ',hr);
            date.setHours(hr);
          }
          if (splits[1]){
            let mins = parseInt(splits[1]);
            date.setMinutes(mins);
          }
          startVal = date;
        }
        console.log('Start date ',startVal);
        console.log('End date',endVal);
        console.log('Closed Val',closedVal)
        var operatingHrId = "operatingHr"+opIdx;
        var day = operatingHrId+"_day";
        var desc = operatingHrId+"_start";
        var price = operatingHrId+"_end";
        var isClosed = operatingHrId+"_closed";
        var is24Hrs = operatingHrId+"_24hrs";
        var startDate = 'operatingHr'+i+"_start";
        var endDate = 'operatingHr'+i+"_end";
        updated[[startDate]] =  startVal;
        updated[[endDate]] =  endVal;
        updated[[day]] =  dayVal;
        updated[[isClosed]] = closedVal;
        
        arr.push({"id": operatingHrId, day:dayVal,desc : "" , isClosed : closedVal,is24Hrs:hrs24Val,[startDate]:startVal,[endDate]:endVal});
        opIdx = opIdx + 1
      }

      setCarwashFields(updated);
      setOperatingHrArrIdx(opIdx);
      setOperatingHrArr(arr);
      
    }
  }

  function getCarwashTypeFromSuggestion(item){
    let typeVal = 'dt'
    if(item){
      let type = item['type'];
      typeVal = getCarwashStringFromType(type);
      if(!typeVal){
        typeVal = 'dt'
      }
    }
    console.log('carwash type string is ',typeVal);
    return typeVal;
  }

  function getCarwashStringFromType(type){
    console.log('requesting for type ',type);
    console.log('type keys ',Object.keys(carwashTypeMap));
    let retVal = null;
    Object.keys(carwashTypeMap).forEach(function(key) {

      let val = carwashTypeMap[key];
      console.log('comparing value ',val);
      if (val === type){
        console.log('returning key as ',key);
        retVal = key;
        return;
      }
    });
    return retVal;
  }

  function handleClose(){
      resetCarWashAddStates();
      props.resetGeocodeResults();
      props.onDialogAction('Close')
  }

  function onAddPricingAction(){
    console.log('onAddPricingAction ENTRY', priceArrIdx);
    var arr = new Array();
    for (var i = 0; i < pricingArr.length; i++){
      arr.push(pricingArr[i]);
    }
    var pricingId = "pricing"+priceArrIdx;
    var name = pricingId+"_name";
    var desc = pricingId+"_desc";
    var price = pricingId+"_price";
    
    arr.push({"id": pricingId, name:"",desc : "" , price : 0.0});
    console.log('Total number of pricing arrays ',arr);
    var pIdx = parseInt(priceArrIdx) + 1
    console.log('New Index is ',pIdx);
    setPricingArr(arr);
    setPriceArrayIdx(pIdx);
  }

  function onPricingDeleteAction(item){
    console.log('onPricingDeleteAction == ENTRY');
    console.log('item for deletion = ',item);
    var arr = new Array();
    for (var i = 0; i < pricingArr.length; i++){
      var it = pricingArr[i];
      if(it.id === item.id){
        continue;
      }
      arr.push(pricingArr[i]);
    }
    setPricingArr(arr);
  }

  function onGeocode(){
    console.log('geocode request ',carwashFields);
    var line1 = carwashFields["address.line1"];
    line1 = line1 ? line1 : '';
    var line2 = carwashFields["address.line2"];
    line2 = line2 ? line2 : '';
    var city = carwashFields["address.city"];
    city = city ? city : '';
    var zip = carwashFields["address.zip"];
    zip = zip ? zip : '';
    var str = Utilities.getAddressForGeocoding(name,line1,line2,zip,city);
    props.performGeoCode(str);
  }

  function onAddOperatingHrAction(){
    console.log('onAddOperatingHrAction ENTRY', operatingHrArrIdx);
    var arr = new Array();
    for (var i = 0; i < operatingHrArr.length; i++){
      arr.push(operatingHrArr[i]);
    }
    var operatingHrId = "operatingHr"+operatingHrArrIdx;
    var day = operatingHrId+"_day";
    var desc = operatingHrId+"_start";
    var price = operatingHrId+"_end";
    var isClosed = operatingHrId+"_closed";
    var is24Hrs = operatingHrId+"_24hrs";
    
    arr.push({"id": operatingHrId, day:'', desc : "" , isClosed : "",is24Hrs:""});
    console.log('Total number of operatingHr arrays ',arr);
    var pIdx = parseInt(operatingHrArrIdx) + 1
    console.log('New Index is ',pIdx);
    setOperatingHrArr(arr);
    setOperatingHrArrIdx(pIdx);
  }

  function onOperatingHrDeleteAction(item){
    console.log('onPricingOperatingHrAction == ENTRY');
    console.log('item for deletion = ',item);
    var arr = new Array();
    for (var i = 0; i < operatingHrArr.length; i++){
      var it = operatingHrArr[i];
      if(it.id === item.id){
        continue;
      }
      arr.push(operatingHrArr[i]);
    }
    setOperatingHrArr(arr);
  }

  const handleChange = prop => event => {
    console.log('handleChange Entry prop='+prop+'value : '+event.target.value);
    if(prop == 'coordinates'){
      //user trying to edit, clear the resolved value. 
      props.resetGeocodeResults();
    }
    setCarwashFields({ ...carwashFields, [prop]: event.target.value });
    if(prop === 'name'){
      setErrorStatus({ ...errorStatus,"name":false});
    }
    if(prop === 'address.line1'){
      setErrorStatus({ ...errorStatus,'address.line1':false});
    }
    if(prop === 'address.city'){
      setErrorStatus({ ...errorStatus,'address.city':false});
    }
    if(prop === 'address.zip'){
      setErrorStatus({ ...errorStatus,'address.zip':false});
    }
    if(prop === 'address.state'){
      setErrorStatus({ ...errorStatus,'address.state':false});
    }
    if(prop === 'coordinates'){
      setErrorStatus({ ...errorStatus,'coordinates':false});
    }
    if(prop === 'radius'){
      setErrorStatus({ ...errorStatus,'radius':false});
    }
  };

  const handleCheckboxChange = prop => event => {
    console.log("handleCheckboxChange ==",prop + ', checked '+event.target.checked);
    setCarwashFields({ ...carwashFields, [prop]: event.target.checked });
    console.log('updated carwash fields', carwashFields);
  }

  

  function getDialogContent(){
      console.log('getDialogContent ENTRY' , carwashFields);
      return createCarWashAddDialogJSX()
  }

  function getDialogActions(){
    return addCarwashDialogActionJSX();
  }

  function addCarwashDialogActionJSX(){
    return (<DialogActions>
      {props.isCarwashAddError ?  <Typography color='error'> {props.carwashAddErrorMsg}</Typography> : '' }
      {props.hasAddedCarwash ?  <Typography color='error'> {'Carwash added successfully.'}</Typography> : '' }
      <Button variant="outlined" onClick={onNegativeAction} color="primary">
        {'Cancel'}
      </Button> 
      <Button variant="outlined" onClick={onClearFormAction} color="primary">
        {'Clear'}
      </Button> 
      <Button variant="outlined" onClick={onPositiveAction} disabled={props.isAddingCarwash}>
           {props.isAddingCarwash ? <CircularProgress size={22}/> : ''}
           &nbsp;&nbsp;{props.isAddingCarwash ? 'Adding ...' : 'Add Carwash' }
      </Button>
      
   </DialogActions>)
  }

  const handleRadioChange = event => {
    console.log('radio change event',event.target.value);
    setCarwashType(event.target.value);
  };

  function createCarWashAddDialogJSX(){
    
    if(!pricingArr || pricingArr.length == 0){
      onAddPricingAction();
    }
    if(!operatingHrArr || operatingHrArr.length == 0){
      onAddOperatingHrAction();
    }
    return (
      
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form className={classes.root} >
      
      <div>
        <Typography variant='h6'>Name &amp; address</Typography>
        <TextField className={classes.textField } required id="standard-required" error={errorStatus.name} label="Name" value={carwashFields.name}
          onChange={handleChange('name')}/>
        <TextField className={classes.textField } required id="standard-required" label="Address Line 1" error={errorStatus["address.line1"]} value={carwashFields["address.line1"]}
          onChange={handleChange('address.line1')}/>
        <TextField className={classes.textField } id="standard-required" label="Address Line 2" error={errorStatus["address.line2"]} value={carwashFields["address.line2"]}
          onChange={handleChange('address.line2')}/>
        <TextField className={classes.textField } required id="standard-required" label="City" error={errorStatus["address.city"]} value={carwashFields["address.city"]}
          onChange={handleChange('address.city')}/>
        <TextField className={classes.textField } required id="standard-required" label="Zipcode" error={errorStatus["address.zip"]} value={carwashFields["address.zip"]}
          onChange={handleChange('address.zip')}/>
        <TextField className={classes.textField } required id="standard-required" label="State" error={errorStatus["address.state"]} value={carwashFields["address.state"]}
          onChange={handleChange('address.state')}/>
        {/* <TextField className={classes.textField }  id="standard-required" label="Phone area code" error={errorStatus["phone.areaCode"]} value={carwashFields["phone.areaCode"]}
          onChange={handleChange('phone.areaCode')}/> */}
        <TextField className={classes.textField }  id="standard-required" label="Phone number" error={errorStatus["phone.number"]} value={carwashFields["phone.number"]}
          onChange={handleChange('phone.number')}/>
        <TextField className={classes.textField } required id="standard-required" label="Coordinates" error={errorStatus["coordinates"]} value={props.geoCodeLatLng ? props.geoCodeLatLng : carwashFields["coordinates"] ? carwashFields["coordinates"] : ''}
          onChange={handleChange('coordinates')}/>
        {/* <Button variant="outlined" className={classes.pricingAddButton} onClick={ () => onGeocode()}>
           {props.isGeocoding ? <CircularProgress size={22}/> : ''}
           &nbsp;&nbsp;{props.isGeocoding ? 'Geocoding ...' : 'Geocode' }
       </Button> */}
        {props.geoCodeError ?  <Typography color='error'> {props.geoCodeError}</Typography> : '' }
      </div>
      <br/>
      <div>
        <Typography variant='h6'>Available services</Typography>
         <FormControlLabel
            control={<Checkbox error={errorStatus["services.gas"]} checked={carwashFields["services.gas"]== true ? true : false}
            onChange={handleCheckboxChange('services.gas')} />}
            label="Gas"
          />
          <FormControlLabel
            control={<Checkbox error={errorStatus["services.charge"]} checked={carwashFields["services.charge"]== true ? true : false}
            onChange={handleCheckboxChange('services.charge')} />}
            label="Charge"
          />
         <FormControlLabel
            control={<Checkbox error={errorStatus["services.store"]} checked={carwashFields["services.store"] == true ? true : false}
            onChange={handleCheckboxChange('services.store')} />}
            label="Store"
          />
          <FormControlLabel
            control={<Checkbox  error={errorStatus["services.vaccum"]} checked={carwashFields["services.vaccum"] == true ? true : false}
            onChange={handleCheckboxChange('services.vaccum')} />}
            label="Vaccum"
          />
          <FormControlLabel
            control={<Checkbox error={errorStatus["services.air"]} checked={carwashFields["services.air"]== true ? true : false}
            onChange={handleCheckboxChange('services.air')} />}
            label="Air"
          />
          <FormControlLabel
            control={<Checkbox error={errorStatus["services.mshp"]} checked={carwashFields["services.mshp"] == true ? true : false}
            onChange={handleCheckboxChange('services.mshp')} />}
            label="Membership"
          />
           <FormControlLabel
            control={<Checkbox  error={errorStatus["services.ecofr"]} checked={carwashFields["services.ecofr"]== true ? true : false}
            onChange={handleCheckboxChange('services.ecofr')} />}
            label="Eco Friendly"
          />
           <FormControlLabel
            control={<Checkbox error={errorStatus["services.dtlng"]} checked={carwashFields["services.dtlng"]== true ? true : false}
            onChange={handleCheckboxChange('services.dtlng')} />}
            label="Detailing"
          />
      </div>
      <br/>
      <div>
        <Typography variant='h6'>Type of carwash</Typography>
        <RadioGroup aria-label="position" name="type" value={carwashType} onChange={handleRadioChange} row>
          <FormControlLabel
            value="dtnobrsh"
            control={<Radio color="primary" />}
            label="Drive-through Brushless"
            labelPlacement="end"
          />
          <FormControlLabel
            value="dtnotouch"
            control={<Radio color="primary" />}
            label="Drive-through Touchless"
            labelPlacement="end"
          />
          <FormControlLabel
            value="dtbrsh"
            control={<Radio color="primary" />}
            label="Drive-through Brush"
            labelPlacement="end"
          />
          <FormControlLabel
            value="hw"
            control={<Radio color="primary" />}
            label="Hand wash"
            labelPlacement="end"
          />
          <FormControlLabel
            value="ss"
            control={<Radio color="primary" />}
            label="Self service"
            labelPlacement="end"
          />
          <FormControlLabel
            value="mobile"
            control={<Radio color="primary" />}
            label="Mobile"
            labelPlacement="end"
          />
      </RadioGroup>
      {
        (carwashType === 'mobile') ? <TextField className={classes.textFieldRadius }  id="standard-required" label="Radius" type="number" error={errorStatus.radius} value={carwashFields['radius']}
        onChange={handleChange('radius')} /> : ''
      }
     
      </div>
      <br/>
      <div>
        <Typography variant='h6'>Pricing details</Typography>
        {pricingArr.map(pricing => (
          <div>
          <TextField className={classes.textField }  id="standard-required" label="Name" error={errorStatus[pricing.id + '_name']} value={carwashFields[pricing.id+'_name'] }
          onChange={handleChange(pricing.id+'_name')} />
          <TextField className={classes.textField300 }  id="standard-required" label="Description" error={errorStatus[pricing.id + '_desc']} value={carwashFields[pricing.id+'_desc']}
          onChange={handleChange(pricing.id+'_desc')}/>
          <TextField className={classes.textField }  id="standard-required" label="Price" error={errorStatus[pricing.id + '_price']} value={carwashFields[pricing.id+'_price']}
          onChange={handleChange(pricing.id+'_price')}/>
          <Button variant="outlined" className={classes.pricingAddButton} id={pricing.id} onClick={ onAddPricingAction} color="primary" > {'Add'} </Button> &nbsp;
          <Button variant="outlined" className={classes.pricingAddButton} id={pricing.id} onClick={ () => onPricingDeleteAction(pricing)} color="primary" > {'Delete'} </Button>
          </div>
        ))}
      </div>
      <br/>
      <div>
        <Typography variant='h6'>Operating Hours</Typography>
        {
          operatingHrArr.map(operatingHr => (
          <div>
            <TextField
            id="standard-select-currency"
            select
            label="Select"
            value={carwashFields[operatingHr.id+"_day"]}
            className={classes.textField150}
            onChange={handleChange(operatingHr.id+"_day")}
          >
            {days.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <KeyboardTimePicker
            margin="normal"
            id="time-picker"
            label="Start"
            disabled={carwashFields[operatingHr.id+"_closed"] == true || carwashFields[operatingHr.id+"_24hrs"] == true}
            className={classes.timePicker}
            value={carwashFields[operatingHr.id+"_start"]}
            onChange={handleDateChange(operatingHr.id+"_start")}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
          <KeyboardTimePicker
            margin="normal"
            id="time-picker"
            label="End"
            disabled={carwashFields[operatingHr.id+"_closed"] == true || carwashFields[operatingHr.id+"_24hrs"] == true}
            className={classes.timePicker}
            value={carwashFields[operatingHr.id+"_end"]}
            onChange={handleDateChange(operatingHr.id+"_end")}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
          <FormControlLabel
            control={<Checkbox checked={true} error={errorStatus[operatingHr.id+'_24hrs']} checked={carwashFields[operatingHr.id+'_24hrs'] == true ? true : false}
            onChange={handleCheckboxChange(operatingHr.id+'_24hrs')} />}
            label="24hrs"
          />
          <FormControlLabel
            control={<Checkbox checked={true} error={errorStatus[operatingHr.id+'_closed']} checked={carwashFields[operatingHr.id+'_closed'] == true ? true : false}
            onChange={handleCheckboxChange(operatingHr.id+'_closed')} />}
            label="Closed"
          />
          <Button variant="outlined" className={classes.pricingAddButton} id={operatingHr.id} onClick={ () => onAddOperatingHrAction()} color="primary" > {'Add'} </Button> &nbsp;
          <Button variant="outlined" className={classes.pricingAddButton} id={operatingHr.id} onClick={ () => onOperatingHrDeleteAction(operatingHr)} color="primary" > {'Delete'} </Button>
          </div>
        ))}
        
      </div>
    </form>
    </MuiPickersUtilsProvider>
    )
  }

  return (
    <div>
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={'lg'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Add Carwash'}</DialogTitle>
        <DialogContent>
          {getDialogContent()}
        </DialogContent>
         {getDialogActions()}
      </Dialog>
    </div>
  );
}

const mapStateToProps = function(state){
  return {
    carwashAddErrorMsg : state.carwash.carwashAddErrorMsg,
    isAddingCarwash : state.carwash.isAddingCarwash,
    isCarwashAddError : state.carwash.isCarwashAddError,
    hasInitiatedCarWashAdd : state.carwash.hasAdd,
    hasAddedCarwash : state.carwash.hasAddedCarwash,
    isGeocoding: state.carwash.isGeocoding,
    geoCodeError : state.carwash.geoCodeError,
    geoCodeLatLng : state.carwash.geoCodeResult
  }
}

const mapDispatchToProps = dispatch => 
  bindActionCreators (
    {
      addCarwash:CarwashActions.AddCarwash,
      performGeoCode:CarwashActions.PerformGeocode,
      resetCarwashAddStates:CarwashActions.ResetCarWashAddStates,
      resetGeocodeResults : CarwashActions.resetGeocodeResults
    },
    dispatch,
);

export default connect(mapStateToProps,mapDispatchToProps)(AddCarWashDialog)