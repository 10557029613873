import Utilities from '../utils/Utilities'
import Geocode from "react-geocode";

// const SERVER_URL = 'http://3.15.153.109:1337/v1/';
// const SERVER_URL = 'http://3.15.153.109:1337/v1/';

export const SERVER_URL =  'https://touchless-api.kungfugrip.com/v1/';
// export const SERVER_URL =  'https://touchless-api-dev.kungfugrip.com/v1/';

// const SERVER_URL = 'http://13.59.244.208:1337/v1/';
// const SERVER_URL = 'http://127.0.0.1:1337/v1/';
// const SERVER_URL = 'https://cors-anywhere.herokuapp.com/http://3.15.153.109:1337/v1/';

async function LoginUser(params) {
  try{
    var userName = params.userName;
    var password = params.password;

    console.log('login username ',userName);
    console.log('login password', password);
    var postBody = {'userName' : userName,'password' : password};
    var postStr = JSON.stringify(postBody);
    const json = await prepareBackendPostCall('user/login','POST',postStr,null);
    console.log('json response ',json);
    var result = {"user" : json};
    return result;
  }
  catch(err){
    console.log('car wash add error', err);
    return {"status" : "error", "errMsg" : err.message};
  }
}

async function AddCarwash(params) {
  try{
    console.log('Add carwash post params ',params);
    var postBody = JSON.stringify(params);
    var token = Utilities.getAuthToken();
    const json = await prepareBackendPostCall('carwash/create','POST',postBody,token);
    console.log('json response ',json);
    var result = {"response" : json};
    return result;
  }
  catch(err){
    console.log(err);
    return {"status" : "error", "errMsg" : err.message};
  }
}

async function PerformGeocode(params) {

  var promise = new Promise( (resolve, reject) => {

    try{
      console.log('Perform geocode ENTRY ',params)
      
      // Geocode.setApiKey("AIzaSyDzNtSl669ZAHaUnHZsCbs34d4on6OMKlk");
      Geocode.setApiKey("AIzaSyCgi0jcN7FXSJekZp3fx8VJzDfCfVDFpGM");
      // set response language. Defaults to english.
      Geocode.setLanguage("en");
      // set response region. Its optional.
      // A Geocoding request with region=es (Spain) will return the Spanish city.
      Geocode.setRegion("es");
      // Enable or disable logs. Its optional.
      Geocode.enableDebug();
      console.log('PerformGeocode ',params);
      Geocode.fromAddress(params).then(
        response => {
          console.log("response received for geocoding ",response);
          const { lat, lng } = response.results[0].geometry.location;
          console.log(lat, lng);
          resolve({"status":"success","coordinates":[lat,lng]});
        },
        error => {
          console.log("Geocode error ",error);
          if(params.startsWith('Test')){
            resolve({"status":"success","coordinates":[39.189536, -106.190655]});
          }
          else {
            var errMsg = error ? error.message : 'Geocoding error';
            console.log('error message is ',errMsg)
            errMsg = errMsg.replace("undefined", "");
            if(!errMsg) {
              errMsg =  'Geocoding error'
            }
            else{
              errMsg = 'Geocode error' + errMsg
            }
            resolve({"status":"error","errMsg":errMsg });
          }
          // 
        }
      );
    }
    catch(err){
      console.log('Exception in geocoding', err);
      resolve({"status":"error","errMsg":err.message ? err.message: 'exception caught while geocoding' });
    }
   });
   return promise;
  
}

async function retreveEditSuggestions(params) {
  try{
    console.log('retreveEditSuggestions post params ',params);
    var postBody = JSON.stringify(params);
    var token = Utilities.getAuthToken();
    const json = await prepareBackendPostCall('carwash/edits','POST',postBody,token);
    console.log('json response ',json);
    var result = {"response" : json};
    return result;
  }
  catch(err){
    console.log(err);
    return {"status" : "error", "errMsg" : err.message};
  }
}

async function retreveAddSuggestions(params) {
  try{
    console.log('retreveAddSuggestions post params ',params);
    var postBody = JSON.stringify(params);
    var token = Utilities.getAuthToken();
    const json = await prepareBackendPostCall('carwash/suggestions','POST',postBody,token);
    console.log('json response ',json);
    var result = {"response" : json};
    return result;
  }
  catch(err){
    console.log(err);
    return {"status" : "error", "errMsg" : err.message};
  }
}

async function deleteAddSuggestion(params) {
  try{
    console.log('deleteAddSuggestion post params ',params);
    var postBody = JSON.stringify(params);
    var token = Utilities.getAuthToken();
    const json = await prepareBackendPostCall('carwash/suggestions/delete','POST',postBody,token);
    console.log('json response ',json);
    var result = {"response" : json};
    return result;
  }
  catch(err){
    console.log(err);
    return {"status" : "error", "errMsg" : err.message};
  }
}



async function prepareBackendPostCall(url, method = 'POST', data = {}, token){
  try{
    console.log('prepareBackendGetCall ENTRY');
    const endPoint = SERVER_URL + url;
    Utilities.logInfo('making http calls to '+endPoint);
    const result = await getServerResponse('post',endPoint,getJSONHeaders(token),data);
    Utilities.logInfo('result for get API XHR ',result);
    var jsonData = result;
    Utilities.logInfo('JSON response data '+JSON.stringify(jsonData));
    return jsonData;
  }
  catch(err){
    console.log('Exception caught in backendPostcall');
    return {"status" : "error", "errMsg" : err.message};
  }
}



async function prepareBackendGetCall(url, method = 'GET', data = {}, token){
  try{
    console.log('prepareBackendGetCall ENTRY');
    const endPoint = SERVER_URL + url;
    Utilities.logInfo('making http calls to '+endPoint);
    const result = await getServerResponse('get',endPoint,getJSONHeaders(token));
    Utilities.logInfo('result for get API XHR ',result);
    var jsonData = result;
    Utilities.logInfo('JSON response data '+JSON.stringify(jsonData));
    return jsonData;
  }
  catch(err){
    Utilities.logError("GET call exception "+err);
    return false;
  }
}

function getServerResponse(method, url, headers, body){
  if (method == null) method = 'get';
  return new Promise(function(resolve,reject){
    var xhr = new XMLHttpRequest();
    xhr.open(method,url,true);
    if(headers){
      Object.keys(headers).forEach(function(key) {
        xhr.setRequestHeader(key,headers[key]);
      });
    }
    xhr.responseType = 'json';
    xhr.onload = function () {
      var status = xhr.status;
      console.log('xHR status is '+status);
      if(status == 200){
        resolve (xhr.response);
      }
      else {
        reject(status);
      }
    };
    if(body){
      xhr.send(body);
    }
    else {
      xhr.send();
    }
  });
}

function getJSONHeaders(token){
  if(!token){
    return {
      'Accept' : 'application/json',
      'Content-Type' : 'application/json',
      'apikey' : '$2ab$10$.CDkZU3.3A09DCF6-2BC2-45E0-A957-576E2A93EC61'
    };
  }
  return {
    'Accept' : 'application/json',
    'Content-Type' : 'application/json',
    'token' : token,
    'apikey' : '$2ab$10$.CDkZU3.3A09DCF6-2BC2-45E0-A957-576E2A93EC61'
  }
}

export {
  LoginUser,
  AddCarwash,
  PerformGeocode,
  retreveEditSuggestions,
  retreveAddSuggestions,
  deleteAddSuggestion
};