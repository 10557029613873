import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {CarwashActions} from '../actions';
import {bindActionCreators} from 'redux'
import { dispatch } from 'rxjs/internal/observable/pairs';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MaterialTable,{ MTableToolbar} from 'material-table'
import { deleteAddSuggestion } from '../services/NetworkingService';
import AlertDialog from '../AlertDialog'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function AcceptNewCarwash(props) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
  const [selectedCarwashSuggestion, setSelectedCarwashSuggestion] = React.useState(false);

  function getDialogContent(){
    return (
      <MaterialTable
        title="Carwash Edit Suggestions"
        isLoading={props.addSuggestionRetrieveInProgress || props.addSuggestionDeleteInProgress}
        // actions={[
        //   {
        //     icon: 'save',
        //     tooltip: 'Save User',
        //     onClick: (event, rowData) => {
        //       // Do save operation
        //     }
        //   }
        // ]}
        actions={[
          {
            icon: 'save',
            tooltip: 'Save Suggestion',
            onClick: (event, rowData) => {props.onDialogAction('show_detail',rowData);}
          },
          {
            icon: 'delete',
            tooltip: 'Delete Suggestion',
            onClick: (event, rowData) => {
              setSelectedCarwashSuggestion(rowData);
              setShowDeleteConfirmation(true);
            } 
          }
        ]}
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'By', field: 'userName'},
          { title: 'User ID', field: 'userID' }
        ]}
        data={props.addSuggestions ? props.addSuggestions : []} 
        
        options={{
          grouping: true
        }}
      />
    )
  }

  useEffect(()=>{
    props.clearDeleteAddSuggestions();
    props.retrieveAddSuggestions();
  },[]);

  function handleClose(){

  }

  function deleteAddSuggestion(suggestionData){
    console.log('Delete Add suggestion ENTRY ',suggestionData);
    let id = suggestionData.id;
    props.deleteAddSuggestion(id);
  }
  
  function onNegativeAction(){
    props.onDialogAction('cancel');
  }
  
  
  function onPositiveAction(){
  
  }

  function onDeleteSuggestionDialogAction(action){
    if(action === 'Confirm'){
      setShowDeleteConfirmation(false);
      props.deleteAddSuggestion(selectedCarwashSuggestion.id);
    }
    else {
      setShowDeleteConfirmation(false);
    }
  }
  
  function deleteConfirmationDialogJSX(){
    console.log('showDeleteConfirmationDialog ENTRY');
    var message = 'Are you sure you want this car wash suggestion?';
    return <AlertDialog title={'Delete?'} message={message} pAction={'Confirm'} nAction={'Cancel'} onDialogAction={onDeleteSuggestionDialogAction}/>;
  }

  function getDialogActions(){
    return (<DialogActions>
      <Button variant="outlined" onClick={onNegativeAction} color="primary">
        {'Cancel'}
      </Button>
   </DialogActions>)
  }

  return (
    <div>
    {showDeleteConfirmation ? deleteConfirmationDialogJSX() : ''}
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={'md'}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Accept Carwash Suggestions'}</DialogTitle>
      <DialogContent>
        {getDialogContent()}
      </DialogContent>
       {getDialogActions()}
    </Dialog>
    </div>
  );
}


const mapStateToProps = function(state){
  console.log('addSuggestionDeleteInProgress => ',state.carwash.addSuggestionDeleteInProgress);
  return {
    addSuggestionRetrieveInProgress : state.carwash.addSuggestionRetrieveInProgress,
    addSuggestionError : state.carwash.addSuggestionError,
    addSuggestions : state.carwash.addSuggestions,

    addSuggestionDeleteInProgress : state.carwash.addSuggestionDeleteInProgress,
    addSuggestionDeleteError : state.carwash.addSuggestionDeleteError,
  }
}

const mapDispatchToProps = dispatch => 
  bindActionCreators (
    {
      retrieveAddSuggestions:CarwashActions.RetrieveAddSuggestions,
      deleteAddSuggestion:CarwashActions.DeleteAddSuggestion, 
      clearDeleteAddSuggestions:CarwashActions.ClearDeleteAddSuggestion
    },
    dispatch,
);

export default connect(mapStateToProps,mapDispatchToProps)(AcceptNewCarwash)