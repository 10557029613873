import * as Actions from '../actions/actionTypes'

const iniitalState = {
  notificationSendErrorMsg : null,
  isSendingNotification : false,
  isSendNotificationSuccess : false,
  isSendingNotificationError : false
};

const NotificationsReducer = (state = iniitalState, action) => {
  switch(action.type){
    case Actions.SEND_NOTIFICATION:
    {
       return {
        ...state,
        isSendingNotification: false,
        isSendingNotificationError:false,
        isSendNotificationSuccess:false,
        notificationSendErrorMsg:null
       }
    }
    case Actions.SEND_NOTIFICATION_REQUEST:{
      return {
        ...state,
        isSendingNotification: true,
        isSendingNotificationError:false,
        isSendNotificationSuccess:false,
        notificationSendErrorMsg:null
      }
    }
    case Actions.SEND_NOTIFICATION_ERROR:{
      console.log('notification  response is called for error',action);
      return {
        ...state,
        isSendingNotification: false,
        isSendingNotificationError:true,
        isSendNotificationSuccess:false,
        notificationSendErrorMsg:action.result.errMsg
      }
    }
    case Actions.SEND_NOTIFICATION_RESPONSE:{
      console.log('send notification response is called ',action);
      return {
        ...state,
        isSendingNotification: false,
        isSendingNotificationError:false,
        isSendNotificationSuccess:true,
        notificationSendErrorMsg:null
      }
    }
    case Actions.RESET_SEND_NOTIFICATION_STATE:{
      state = iniitalState;
      return{
        ...state
      }
    }
    default:{
      return{
        ...state
      }
    }
  }
};

export default NotificationsReducer;