import {appStore} from '../reducers'

export default ({
  getBasicAuthHeaderFromCredentials(username,password){
    var bEncoded = btoa(username + ":"+ password);
    console.log("Base64 encoded "+bEncoded);
    var encoded = bEncoded;
    return 'Basic '+encoded; 
  },

  getAddressForGeocoding(name, line1,line2, zip, city){
    var address = [];
    // if (name && name.length > 0){
    //   address.push(name);
    // }
    if (line1 && line1.length > 0){
      address.push(line1);
    }
    if (line2 && line2.length > 0){
      address.push(line2);
    }
    if (city && city.length > 0){
      address.push(city);
    }
    if (zip && zip.length > 0){
      address.push(zip);
    }
    var str = address.join(',');
    return str;
  },

  getAuthToken(){
    if(appStore){
      var appStoreState = appStore.getState();
      if(appStoreState && appStoreState.login && appStoreState.login.user && appStoreState.login.user.token)
      var token = appStore.getState().login.user.token.access_token;
      console.log('token info from the store is ',token);
      return token;
    }
    return '';
  },

  isLoggedIn(){
    if(appStore){
      var appStoreState = appStore.getState();
      return appStoreState.login.isLoggedIn;
    }
    return false;
  },

  getStateAbbrevationMapping(abbr){
    let mapping = {
      "AL": "Alabama",
      "AK": "Alaska",
      "AS": "American Samoa",
      "AZ": "Arizona",
      "AR": "Arkansas",
      "CA": "California",
      "CO": "Colorado",
      "CT": "Connecticut",
      "DE": "Delaware",
      "DC": "District Of Columbia",
      "FM": "Federated States Of Micronesia",
      "FL": "Florida",
      "GA": "Georgia",
      "GU": "Guam",
      "HI": "Hawaii",
      "ID": "Idaho",
      "IL": "Illinois",
      "IN": "Indiana",
      "IA": "Iowa",
      "KS": "Kansas",
      "KY": "Kentucky",
      "LA": "Louisiana",
      "ME": "Maine",
      "MH": "Marshall Islands",
      "MD": "Maryland",
      "MA": "Massachusetts",
      "MI": "Michigan",
      "MN": "Minnesota",
      "MS": "Mississippi",
      "MO": "Missouri",
      "MT": "Montana",
      "NE": "Nebraska",
      "NV": "Nevada",
      "NH": "New Hampshire",
      "NJ": "New Jersey",
      "NM": "New Mexico",
      "NY": "New York",
      "NC": "North Carolina",
      "ND": "North Dakota",
      "MP": "Northern Mariana Islands",
      "OH": "Ohio",
      "OK": "Oklahoma",
      "OR": "Oregon",
      "PW": "Palau",
      "PA": "Pennsylvania",
      "PR": "Puerto Rico",
      "RI": "Rhode Island",
      "SC": "South Carolina",
      "SD": "South Dakota",
      "TN": "Tennessee",
      "TX": "Texas",
      "UT": "Utah",
      "VT": "Vermont",
      "VI": "Virgin Islands",
      "VA": "Virginia",
      "WA": "Washington",
      "WV": "West Virginia",
      "WI": "Wisconsin",
      "WY": "Wyoming"
    }
    return mapping[abbr];
  },

  logInfo(message){
    console.log(message);
  },

  
})