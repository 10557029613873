import * as Actions from '../actions/actionTypes'
import { stat } from 'fs';

const iniitalState = {
  user : {
    userName : '',
    password : '',//probably to clear it after the login is success / error 
    token : '',
    properties : {}//holds app specific properties contained in the login response.
  },
  loginError : false,
  loginErrorMsg : '',
  isLoggingIn : false,
  isLoggedIn : false
};

const LoginReducer = (state = iniitalState, action) => {
  switch(action.type){
    case Actions.LOGIN_USER:
    {
       var user = {userName:action.userName, password : action.password }
       return {
         ...state,
         isLoggingIn : true,
         isLoggedIn : false,
         user
       }
    }
    case Actions.LOGIN_USER_REQUEST:{
      return {
        ...state,
        isLoggingIn : true,
      }
    }
    case Actions.LOGIN_USER_ERROR:{
      console.log('login user response is called for error',action);
      return {
        ...state,
        isLoggingIn : false,
        loginError : true,
        loginErrorMsg :action.result.user.message+'',
      }
    }
    case Actions.LOGIN_USER_RESPONSE:{
      console.log('login user response is called ',action);
      var loginResponse = action.result;
      var properties = loginResponse.user;
      return {
        ...state,
        user : properties,
        isLoggedIn : true,
      }
    }
    case Actions.LOGIN_CLEAR_LOGIN_DATA:{
      state = iniitalState;
      return{
        ...state
      }
    }
  }
  return state;
};

export default LoginReducer;