import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import {CarwashActions} from '../actions';
import {bindActionCreators} from 'redux'
import { dispatch } from 'rxjs/internal/observable/pairs';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MaterialTable,{ MTableToolbar} from 'material-table'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function AcceptEditDialog(props) {

  function getDialogContent(){
    return (
      <MaterialTable
        title="Carwash Edit Suggestions"
        isLoading={props.editSuggestionRetrieveInProgress}
        // actions={[
        //   {
        //     icon: 'save',
        //     tooltip: 'Save User',
        //     onClick: (event, rowData) => {
        //       // Do save operation
        //     }
        //   }
        // ]}
        columns={[
          { title: 'ID', field: 'cwID', defaultGroupOrder: 0 },
          { title: 'By', field: 'uName'},
          { title: 'Field', field: 'field' },
          { title: 'Current', field: 'current', type: 'string' },
          { title: 'Suggestion', field: 'edit', type: 'string' },
        ]}
        data={props.editSuggestions ? props.editSuggestions : []} 
        
        editable={{
          onBulkUpdate: changes =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve();
            }, 1000);
          }),     
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                // setData([...data, newData]);
                resolve();
              }, 1000)
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                // const dataUpdate = [...data];
                // const index = oldData.tableData.id;
                // dataUpdate[index] = newData;
                // setData([...dataUpdate]);
  
                resolve();
              }, 1000)
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                // const dataDelete = [...data];
                // const index = oldData.tableData.id;
                // dataDelete.splice(index, 1);
                // setData([...dataDelete]);
  
                resolve();
              }, 1000)
            }),
        }}       
        options={{
          grouping: true
        }}
      />
    )
  }

  useEffect(()=>{
    props.retrieveEditSuggestions();
  },[]);

  function handleClose(){

  }
  
  function onNegativeAction(){
    props.onDialogAction('cancel');
  }
  
  
  function onPositiveAction(){
  
  }
  
  function getDialogActions(){
    return (<DialogActions>
      {props.isSuggestionError ?  <Typography color='error'> {props.saveSuggestionStatus}</Typography> : '' }
      {props.isSuggestionSuccess ?  <Typography color='error'> {'Carwash suggestion(s) saved successfully.'}</Typography> : '' }
      <Button variant="outlined" onClick={onNegativeAction} color="primary">
        {'Cancel'}
      </Button> 
      <Button variant="outlined" onClick={onPositiveAction} disabled={props.isSavingSuggestion}>
           {props.isSavingSuggestion ? <CircularProgress size={22}/> : ''}
           &nbsp;&nbsp;{props.isSavingSuggestion ? 'Saving ...' : 'Save Suggestions' }
      </Button>
      
   </DialogActions>)
  }

  return (
    <div>
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth={'md'}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Accept Edit Suggestions'}</DialogTitle>
      <DialogContent>
        {getDialogContent()}
      </DialogContent>
       {getDialogActions()}
    </Dialog>
    </div>
  );
}


const mapStateToProps = function(state){
  return {
    saveSuggestionStatus : state.carwash.saveSuggestionStatus,
    isSavingSuggestion : state.carwash.isSavingSuggestion,
    isSuggestionSuccess : state.carwash.isSuggestionSuccess,
    isSuggestionError : state.carwash.isSuggestionError,

    editSuggestionRetrieveInProgress : state.carwash.editSuggestionRetrieveInProgress,
    editSuggestionError : state.carwash.editSuggestionError,
    editSuggestions : state.carwash.editSuggestions,
  }
}

const mapDispatchToProps = dispatch => 
  bindActionCreators (
    {
      saveSuggestion:CarwashActions.AddCarwash,
      retrieveEditSuggestions:CarwashActions.RetrieveEditSuggestions
    },
    dispatch,
);

export default connect(mapStateToProps,mapDispatchToProps)(AcceptEditDialog)