import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);

  function onNegativeAction(title) {
    props.onDialogAction(props.nAction)
  }

  function onPositiveAction() {
    props.onDialogAction(props.pAction)
  }

  function handleClose(){
      props.onDialogAction('Close')
  }

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            {props.nAction ? <Button onClick={onNegativeAction} color="primary">
            {props.nAction}
          </Button> : ''}
          {props.pAction? <Button onClick={onPositiveAction} color="primary" autoFocus>
            {props.pAction}
          </Button> : ''}
        </DialogActions>
      </Dialog>
    </div>
  );
}