import * as ActionTypes from './actionTypes';

export const AddCarwash = (carwashParams) => (
    {
        type : ActionTypes.ADD_CARWASH,
        carwashParams
    }
);

export const RetrieveEditSuggestions = () => (
    {
        type : ActionTypes.RETRIEVE_EDIT_SUGGESTIONS,
    }
);

export const RetrieveAddSuggestions = () => (
    {
        type : ActionTypes.RETRIEVE_ADD_SUGGESTIONS,
    }
);

export const ResetCarWashAddStates = () => (
    {
        type : ActionTypes.RESET_CAR_WASH_ADD_STATES,
    }
);

export const DeleteAddSuggestion = (suggestionId) => (
    {
        type : ActionTypes.DELETE_ADD_SUGGESTIONS,
        suggestionId
    }
);
export const ClearDeleteAddSuggestion = () => (
    {
        type : ActionTypes.DELETE_ADD_SUGGESTIONS_CLEAR
    }
);

export const resetGeocodeResults = () => (
    {
        type : ActionTypes.RESET_GEOCODE_STATE,
    }
);

export const PerformGeocode = (address) => (
    {
        type : ActionTypes.PERFORM_GEOCODE,
        address
    }
);