import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux'
import { NotificationActions } from '../actions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    
  },
  textFieldDropdown: {
    margin:'18px',
    flexBasis: 200,
  },
  textFieldDropdownBig: {
    margin:'18px',
    width : 200,
  },
  textFieldDropdownSmall: {
    margin:'18px',
    width : 100,
  },
  textFieldDropdownMedium: {
    margin:'18px',
    width : 150,
  },
  note: {
    flex: 1,
  },
}));

const mediumTypes = [
  {
    value: 'notification',
    label: 'Notification',
  },
  {
    value: 'email',
    label: 'Email',
  },
];

const subscriptionTopics = [
  {
    value: 'all_ios',
    label: 'All iOS',
  },
  {
    value: 'all_android',
    label: 'All Android',
  },
];

const targetTypes = [
  {
    value: 'topic',
    label: 'Topic',
  },
  {
    value: 'user_specific',
    label: 'User Specific',
  },
];


function SendNotificationDialog(props) {
  const classes = useStyles();
  const [notificationFields, setFolloupFields] = React.useState({"name" : getPropsValue("name",props.item),
  "email" : getPropsValue("email",props.item),
  "title" : getPropsValue("title",props.item),
  "medium" : "notification",
  "senderName" : getPropsValue("senderName",props.item),
  "commEmail": getPropsValue("commEmail",props.item),
  "senderDesignation": getPropsValue("senderDesignation",props.item),
  "senderEmail": getPropsValue("senderEmail",props.item),
  "message": getPropsValue("message",props.item),
});

  const sendNotification = (notificationParams) => props.sendFollowupNotification({'followupParams' : notificationParams});

  function getPropsValue(fieldName, item){
    console.log('getting props field ',item);
    if(item){
      console.log('Field for key '+fieldName+'is ',item[fieldName]);
      return item[fieldName];
    }
    return '';
  }

  function onNegativeAction(title) {
    props.onDialogAction('Cancel')
  }

  function onPositiveAction() {
    console.log('Notification dialog positive action');
    var medium = notificationFields.medium;
    var name = notificationFields.name;
    var title = notificationFields.title;
    var email = notificationFields.email;
    var senderName = 'KungfuGrip Admin';
    var commEmail = notificationFields.email;
    var senderDesignation = 'KungfuGrip';
    var senderEmail = "admin@kungfugrip.com";
    var message = notificationFields.message;

    var notificationParams = {'medium':medium,'name':name,'title':title,'email':email,
      'senderName' : senderName,'commEmail' : commEmail, 'senderDesignation' : senderDesignation,
      'senderEmail' : senderEmail, 'message' : message
    }
    console.log('Notification params are ',notificationParams);
    sendNotification(notificationParams);
  }

  function handleClose(){
      props.onDialogAction('Close')
  }

  const handleChange = prop => event => {
    console.log('handleChange Entry prop='+prop+'value : '+event.target.value);
    setFolloupFields({ ...notificationFields, [prop]: event.target.value });
 
  };

  return (
    <div>
      <Dialog
        open={true}
        maxWidth={'md'}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Send Notification to '+notificationFields['name']}</DialogTitle>
        <DialogContent>
        <div >
          <TextField
              select
              label="Type"
              className={classes.textFieldDropdown}
              value={notificationFields.medium}
              onChange={handleChange('medium')}
            >
              {mediumTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Target"
              className={classes.textFieldDropdownMedium}
              value={notificationFields.subscriptionTopic}
              onChange={handleChange('subscriptionTopic')}
            >
              {targetTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Subscription Topic"
              className={classes.textFieldDropdownBig}
              value={notificationFields.subscriptionTopic}
              onChange={handleChange('subscriptionTopic')}
            >
              {subscriptionTopics.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
        </div>
        <div>
          <TextField
            id="outlined-name"
            label="Notification/Email Title"
            className={classes.textField}
            fullWidth={true}
            value={notificationFields.title}
            onChange={handleChange('title')}
            margin="normal"
            variant="outlined"
          />
        </div>
        <div>
          <TextField
            id="outlined-name"
            label="Notification Message Body"
            className={classes.textField}
            fullWidth={true}
            multiline
            rows="4"
            value={notificationFields.message}
            onChange={handleChange('message')}
            margin="normal"
            variant="outlined"
          />
        </div>
        <div>
        <Typography color="textSecondary" className={classes.note}>
            {props.sendNotificationStatus && props.sendNotificationStatus.length > 0 ? props.sendNotificationStatus : 'Press Send to send notifications'} 
         </Typography>
         </div>
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" onClick={onNegativeAction} color="primary">
              {'Cancel'}
            </Button> 
            <Button variant="outlined" onClick={onPositiveAction} color="primary" autoFocus>
              {'Send'}
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = function(state){
  console.log('Send Notification dialog ');
  return {
    isSendingNotification : state.notifications.isSendingNotification,
    statusMsg : state.notifications.notificationSendErrorMsg,
  }
}

const mapDispatchToProps = dispatch => 
  bindActionCreators (
    {
      sendUserNotification:NotificationActions.SendNotification,
    },
    dispatch,
);

export default connect(mapStateToProps,mapDispatchToProps)(SendNotificationDialog)