import * as ActionTypes from './actionTypes';

export const LoginUser = (userName,password) => (
    {
        type : ActionTypes.LOGIN_USER,
        userName,
        password
    }
);

export const LogoutUser = () => (
    {
        type : ActionTypes.LOGIN_CLEAR_LOGIN_DATA,
    }
);