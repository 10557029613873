import React, { Component,useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {history} from '../reducers'

import {LoginActions} from '../actions';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux'
import { dispatch } from 'rxjs/internal/observable/pairs';
import { Dialog, DialogContent } from '@material-ui/core';
import { Transition } from 'react-transition-group';
import LoginBG from '../static/images/login/login_popup_bg.png'
import Utilities from '../utils/Utilities';

function LoginStatus(props) {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {props.message}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${LoginBG})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(2, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    // margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function LoginPopup(props) {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const performSignIn = () => {
    var userType = 'user';
    var trimmedUserName = username;
    props.performSignIn(trimmedUserName,password,userType);
  }
  const handleCancelClick = event => {event.preventDefault(); props.onSignInDismiss()}

  return (
    <Dialog 
      open={!props.isLoggedIn}
      maxWidth={'sm'}
      TransitionComponent={Transition}
      keepMounted
      >
      <DialogContent>
        <Grid container>
        <CssBaseline />
        <Grid item xs={false} md={6} className={classes.image}/>
        <Grid item xs={12} sm={12} md={6} elevation={6}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Enter Username"
                onChange={event => setUsername(event.target.value)}
                name="email"
                value={username}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                onChange={event => setPassword(event.target.value)}
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={(event) => {
                  console.log('perform Sign in called');
                  event.preventDefault();
                  console.log('about to perform Sign in');
                  performSignIn();
                  console.log('peformSignIn finished');
                }}
                className={classes.submit}
              >
              {props.isLoggingIn ? 'Please wait...' : 'Sign In'}
              </Button>
              
              <Grid container direction="row"
                  justify="center"
                  alignItems="center">
                <Link href="#" onClick={handleCancelClick }>
                    Cancel
                  </Link>
              </Grid>
              <Box mt={5}>
                <LoginStatus message={props.loginErrMsg}/>
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
      </DialogContent>
    </Dialog>
  );
}
function onLoginClick(username,password){
  alert('login clicked '+username+","+password );
}

const mapStateToProps = function(state){
  return {
    isLoggingIn:state.login.isLoggingIn,
    isLoggedIn:state.login.isLoggedIn,
    loginErrMsg : state.login.loginErrorMsg
  }
}

const mapDispatchToProps = dispatch => 
  bindActionCreators (
    {
      performSignIn:LoginActions.LoginUser,
    },
    dispatch,
);

export default connect(mapStateToProps,mapDispatchToProps)(LoginPopup)