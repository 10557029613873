import { put, call, takeEvery } from 'redux-saga/effects';
import * as ActionTypes from '../actions/actionTypes'
import * as NetworkingService from '../services/NetworkingService'
import Utilities from '../utils/Utilities'
import { RetrieveAddSuggestions } from '../actions/carwash';

export function* loginUser(action) {   
    console.log('Login user entry action is ',action);
    yield put({type:ActionTypes.LOGIN_USER_REQUEST});
    var reqParams = {'userName' : action.userName, 'password' : action.password};
    console.log('reqParams created for login ',reqParams);
    const result = yield call(NetworkingService.LoginUser,reqParams);
    console.log('result from server response ',result);
    if(result && result.user && result.user.token){
        yield put ({type:ActionTypes.LOGIN_USER_RESPONSE, result });
    }
    else {
        yield put({type : ActionTypes.LOGIN_USER_ERROR, result});
    }
}

export function* addCarWash(action) {   
    console.log('Add carwash action is ',action);
    yield put({type:ActionTypes.ADD_CARWASH_REQUEST});
    var reqParams = action.carwashParams;
    if(reqParams['coordinates'] == null){
        //perform geocode here
        var address = Utilities.getAddressForGeocoding(reqParams['name'],reqParams['address']['line1'],reqParams['address']['line2'],reqParams['address']['zip'],reqParams['address']['city']);
        console.log('request param for geo coding is', address);
        var georesult = yield call(NetworkingService.PerformGeocode,address);
        console.log('result from geocoding is ',georesult);
        if(georesult.coordinates){
            reqParams['coordinates'] = [georesult.coordinates[1],georesult.coordinates[0]]
            yield put ({type:ActionTypes.PERFORM_GEOCODE_RESPONSE, georesult});
        }
        else {
            console.log('result from geocoding is not found ');
            console.log('result from geocoding is not found ',georesult.errMsg);
            const result = {'status': 'error','errMsg': (georesult.errMsg ? georesult.errMsg : 'Unable to geocode the address given')}
            yield put({type : ActionTypes.ADD_CARWASH_ERROR, result});
            return;
        }
    }
    console.log('Request params finally going to the server is ',reqParams);
    //check if coordinates is present. If not, then go ahead and then geocode
    const result = yield call(NetworkingService.AddCarwash,reqParams);
    console.log('result from server response for add carwash ',result);
    if(result && result.response){
        yield put ({type:ActionTypes.ADD_CARWASH_RESPONSE, result});
    }
    else {
        yield put({type : ActionTypes.ADD_CARWASH_ERROR, result});
    }
}

export function* perfromGeocode(action) {   
    console.log('perfromGeocode ENTRY  ',action);
    yield put({type:ActionTypes.PERFORM_GEOCODE_REQUEST});
    var reqParams = action.address;
    const result = yield call(NetworkingService.PerformGeocode,reqParams);
    console.log('result from geocode ',result);
    if(result && result.response){
        yield put ({type:ActionTypes.PERFORM_GEOCODE_RESPONSE, result});
    }
    else {
        yield put({type : ActionTypes.PERFORM_GEOCODE_ERROR, result});
    }
}

export function* retrieveEditSuggestions(action) {   
    console.log('retrieveEditSuggestions ENTRY  ',action);
    yield put({type:ActionTypes.RETRIEVE_EDIT_SUGGESTIONS_REQUEST});
    const result = yield call(NetworkingService.retreveEditSuggestions,{});
    console.log('result from edit suggestions response',result);
    if(result && result.response){
        yield put ({type:ActionTypes.RETRIEVE_EDIT_SUGGESTIONS_RESPONSE, result});
    }
    else {
        yield put({type : ActionTypes.RETRIEVE_EDIT_SUGGESTIONS_ERROR, result});
    }
}

export function* retrieveAddSuggestions(action) {   
    console.log('RetrieveAddSuggestions ENTRY  ',action);
    yield put({type:ActionTypes.RETRIEVE_ADD_SUGGESTIONS_REQUEST});
    const result = yield call(NetworkingService.retreveAddSuggestions,{});
    console.log('result from add suggestions response',result);
    if(result && result.response){
        yield put ({type:ActionTypes.RETRIEVE_ADD_SUGGESTIONS_RESPONSE, result});
    }
    else {
        yield put({type : ActionTypes.RETRIEVE_ADD_SUGGESTIONS_ERROR, result});
    }
}


export function* deleteAddSuggestion(action) {   
    console.log('deleteAddSuggestion ENTRY  ',action);
    let id = action.suggestionId;
    console.log('Suggestion record ID ',id);
    yield put({type:ActionTypes.DELETE_ADD_SUGGESTIONS_REQUEST});
    const result = yield call(NetworkingService.deleteAddSuggestion,{'id':id});
    console.log('result from add suggestions response',result);
    if(result && result.response){
        yield put ({type:ActionTypes.DELETE_ADD_SUGGESTIONS_RESPONSE, result});
    }
    else {
        yield put({type : ActionTypes.DELETE_ADD_SUGGESTIONS_ERROR, result});
    }
    yield put({type:ActionTypes.RETRIEVE_ADD_SUGGESTIONS_REQUEST});
    {
        const result = yield call(NetworkingService.retreveAddSuggestions,{});
        console.log('result from add suggestions response',result);
        if(result && result.response){
            yield put ({type:ActionTypes.RETRIEVE_ADD_SUGGESTIONS_RESPONSE, result});
        }
        else {
            yield put({type : ActionTypes.RETRIEVE_ADD_SUGGESTIONS_ERROR, result});
        }
    }
    
}

export default function* AppSagas() {
   yield takeEvery(ActionTypes.LOGIN_USER, loginUser);
   yield takeEvery(ActionTypes.ADD_CARWASH, addCarWash);
   yield takeEvery(ActionTypes.PERFORM_GEOCODE, perfromGeocode);
   yield takeEvery(ActionTypes.RETRIEVE_EDIT_SUGGESTIONS, retrieveEditSuggestions);
   yield takeEvery(ActionTypes.RETRIEVE_ADD_SUGGESTIONS, retrieveAddSuggestions);
   yield takeEvery(ActionTypes.DELETE_ADD_SUGGESTIONS, deleteAddSuggestion);
}


