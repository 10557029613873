export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGIN_USER_RESPONSE = 'LOGIN_USER_RESPONSE';
export const LOGIN_CLEAR_LOGIN_DATA = 'LOGIN_CLEAR_LOGIN_DATA';//used for logout


export const ADD_CARWASH = 'ADD_CARWASH';
export const ADD_CARWASH_REQUEST = 'ADD_CARWASH_REQUEST';
export const ADD_CARWASH_ERROR = 'ADD_CARWASH_ERROR';
export const ADD_CARWASH_RESPONSE = 'ADD_CARWASH_RESPONSE';
export const RESET_CAR_WASH_ADD_STATES = 'RESET_CAR_WASH_ADD_STATES';


export const PERFORM_GEOCODE = 'PERFORM_GEOCODE';
export const PERFORM_GEOCODE_REQUEST = 'PERFORM_GEOCODE_REQUEST';
export const PERFORM_GEOCODE_ERROR = 'PERFORM_GEOCODE_ERROR';
export const PERFORM_GEOCODE_RESPONSE = 'PERFORM_GEOCODE_RESPONSE';
export const RESET_GEOCODE_STATE = 'RESET_GEOCODE_STATE';



export const RETRIEVE_EDIT_SUGGESTIONS = 'RETRIEVE_EDIT_SUGGESTIONS';
export const RETRIEVE_EDIT_SUGGESTIONS_REQUEST = 'RETRIEVE_EDIT_SUGGESTIONS_REQUEST';
export const RETRIEVE_EDIT_SUGGESTIONS_RESPONSE = 'RETRIEVE_EDIT_SUGGESTIONS_RESPONSE';
export const RETRIEVE_EDIT_SUGGESTIONS_ERROR = 'RETRIEVE_EDIT_SUGGESTIONS_ERROR';
export const RETRIEVE_EDIT_SUGGESTIONS_CLEAR = 'RETRIEVE_EDIT_SUGGESTIONS_CLEAR';

export const RETRIEVE_ADD_SUGGESTIONS = 'RETRIEVE_ADD_SUGGESTIONS';
export const RETRIEVE_ADD_SUGGESTIONS_REQUEST = 'RETRIEVE_ADD_SUGGESTIONS_REQUEST';
export const RETRIEVE_ADD_SUGGESTIONS_RESPONSE = 'RETRIEVE_ADD_SUGGESTIONS_RESPONSE';
export const RETRIEVE_ADD_SUGGESTIONS_ERROR = 'RETRIEVE_ADD_SUGGESTIONS_ERROR';
export const RETRIEVE_ADD_SUGGESTIONS_CLEAR = 'RETRIEVE_ADD_SUGGESTIONS_CLEAR';

export const DELETE_ADD_SUGGESTIONS = 'DELETE_ADD_SUGGESTIONS';
export const DELETE_ADD_SUGGESTIONS_REQUEST = 'DELETE_ADD_SUGGESTIONS_REQUEST';
export const DELETE_ADD_SUGGESTIONS_RESPONSE = 'DELETE_ADD_SUGGESTIONS_RESPONSE';
export const DELETE_ADD_SUGGESTIONS_ERROR = 'DELETE_ADD_SUGGESTIONS_ERROR';
export const DELETE_ADD_SUGGESTIONS_CLEAR = 'DELETE_ADD_SUGGESTIONS_CLEAR';


export const SAVE_SUGGESTIONS = 'SAVE_SUGGESTIONS';
export const SAVE_SUGGESTIONS_REQUEST = 'SAVE_SUGGESTIONS_REQUEST';
export const SAVE_SUGGESTIONS_ERROR = 'SAVE_SUGGESTIONS_ERROR';
export const SAVE_SUGGESTIONS_RESPONSE = 'SAVE_SUGGESTIONS_RESPONSE';
export const RESET_SAVE_SUGGESTIONS_STATE = 'RESET_SAVE_SUGGESTIONS_STATE';


export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const SEND_NOTIFICATION_REQUEST = 'SEND_NOTIFICATION_REQUEST';
export const SEND_NOTIFICATION_ERROR = 'SEND_NOTIFICATION_ERROR';
export const SEND_NOTIFICATION_RESPONSE = 'SEND_NOTIFICATION_RESPONSE';
export const RESET_SEND_NOTIFICATION_STATE = 'RESET_SEND_NOTIFICATION_STATE';