import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import GoogleMapReact from 'google-map-react';
import AddCarWashDialog from '../addcarwash/AddCarWashDialog'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import Utilities from '../utils/Utilities'
import LoginPopup from '../LoginPopup'
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux'
import { dispatch } from 'rxjs/internal/observable/pairs';
import {LoginActions} from '../actions';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import AlertDialog from '../AlertDialog'
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import AcceptEditDialog from '../AcceptEditDialog'
import AcceptNewCarwash from '../AcceptNewCarwash'
import UserNotification from '../UserNotification'
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import GetAppIcon from '@material-ui/icons/GetApp';
import axios from 'axios'
import fileDownload from 'js-file-download'
import {SERVER_URL} from '../services/NetworkingService'

const AnyReactComponent = ({ text }) => <div>{text}</div>;
const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

function MapHome(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showAddDialog, setShowAddDialog] = React.useState(false);
  const [showEditAccept, setShowEditAccept] = React.useState(false);
  const [showAddAccept, setShowAddAccept] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [showLogin,setShowLogin] = React.useState(false);
  const [showLogout, setShowLogout] = React.useState(false);
  const [carwashSuggestion, setCarwashSuggestion] = React.useState(null);
  const [showNotificationDialog, setShowNotificationDialog] = React.useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const center = {lat: 59.95,
                  lng: 30.33}
  const zoom = 11;

  const handleProfileMenuOpen = event => {
    //setAnchorEl(event.currentTarget);
    console.log('Profile menu open clicked');
    if(props.isLoggedIn){
      setShowLogout(true); 
    }
    else {
      setShowLogin(true)
    }
  };

  const handleProfileIconClick = () => {
    console.log('Profile icon clicked');
    setShowLogin(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  function showLogoutConfirmationDialog(){
    console.log('showLogoutConfirmationDialog ENTRY');
    var message = 'Thank you for using Touchless car wash web application. Are you sure you want to logout?';
    return <AlertDialog title={'Logout?'} message={message} pAction={'Logout'} nAction={'Cancel'} onDialogAction={onDialogAction}/>;
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };


  function onDialogAction(action){
    setShowLogout(false);
    if(action === 'Logout'){
      performLogout();
    }
    
  }

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleAddCarWashClick = () => {
    if(props.isLoggedIn){
      setCarwashSuggestion(null);
      setShowAddDialog(true);
    }
  }

  const handleSearchDataDownloadClick = () => {
    if(props.isLoggedIn){
      var token = Utilities.getAuthToken();
      const options = {
        headers: {'token':token, 'apikey' : '$2ab$10$.CDkZU3.3A09DCF6-2BC2-45E0-A957-576E2A93EC61'}
      };
      axios.post(SERVER_URL+ 'carwash/search/download', { 'action' : 'download' }, options)
      .then((res) => {
        console.log('res.data is ',res.data);
        fileDownload(res.data, 'search_no_results.csv')
      })
    }
  }

  const handleSendNotificationClick = () => {
    if(props.isLoggedIn){
      setShowNotificationDialog(true);
    }
  }
  
  const handleAcceptChanges = () => {
    if(props.isLoggedIn){
      setShowEditAccept(true);
    }
  }

  const handleAcceptNewSuggestions = () => {
    if(props.isLoggedIn){
      setShowAddAccept(true);
    }
  }

  

  const onAddCarwashDialogAction = (action,props) => {
    if(action === 'cancel'){
      setShowAddDialog(false);
    }
    else if (action === 'navigate'){
      //take the props and move the map to the center
    }
  }

  const onSaveSuggestionDialogAction = (action,props) => {
    if(action === 'cancel'){
      setShowEditAccept(false);
    }
    else if (action === 'navigate'){
      //take the props and move the map to the center
    }
  }

  const onSaveAddSuggestionDialogAction = (action,props) => {
    if(action === 'cancel'){
      setShowAddAccept(false);
    }
    else if (action === 'show_detail'){
      //take the props and move the map to the center
      console.log('carwash item suggested for addition ',props);
      setCarwashSuggestion(props);
      setShowAddAccept(false);
      setShowAddDialog(true);
    }
  }




  function onSignInDismiss(){
    setShowLogin(false);
  }

  function showLoginPopupJSX(){
    return <LoginPopup onSignInDismiss={onSignInDismiss}/>
  }

  function showEditAcceptJSX (){
    return <AcceptEditDialog onDialogAction={onSaveSuggestionDialogAction}/>
  }

  function showNotificationDialogJSX(){
    return <UserNotification/>
  }

  function showAddAcceptJSX (){
    return <AcceptNewCarwash onDialogAction={onSaveAddSuggestionDialogAction}/>
  }

  const performLogout = () => {
    console.log('performLogout ENTRY',props);
    props.performLogout();
  }

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>

    </Menu>
  );

  return (
    <div className={classes.grow}>
     {showEditAccept ? showEditAcceptJSX() : ''}
     {showAddAccept ? showAddAcceptJSX() : ''}
     {showLogin ? showLoginPopupJSX() : ''}
     {showLogout ? showLogoutConfirmationDialog() : ''}
     {showNotificationDialog ? showNotificationDialogJSX() : ''}
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Touchless CarWash
          </Typography>
          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div> */}
          <div className={classes.grow} />
          {/* <div className={classes.sectionDesktop}> */}
          <div >
            {/* <div onClick={handleProfileIconClick}>
              {Utilities.isLoggedIn() ? <Avatar alt="Logged in" src={Utilities.getLoggedInProfileImage()}/> :  <AccountCircleIcon/>  }
            </div> */}
            <Tooltip title="Accept Add Suggestions">
            <IconButton aria-label="Accept the changes" color="inherit" onClick={handleAcceptNewSuggestions}>
               {props.isLoggedIn ? <AddToPhotosIcon/> : ''}
            </IconButton>
            </Tooltip>
            <Tooltip title="Accept Edit Suggestions">
            <IconButton aria-label="Accept the changes" color="inherit" onClick={handleAcceptChanges}>
               {props.isLoggedIn ? <EditAttributesIcon/> : ''}
            </IconButton>
            </Tooltip>
            <Tooltip title="Add New Carwash">
            <IconButton aria-label="Add new Carwash" color="inherit" onClick={handleAddCarWashClick}>
               {props.isLoggedIn ? <AddLocationIcon/> : ''}
            </IconButton>
            </Tooltip>
            <Tooltip title="Download No results file">
            <IconButton aria-label="Download Search data" color="inherit" onClick={handleSearchDataDownloadClick}>
               {props.isLoggedIn ? <GetAppIcon/> : ''}
            </IconButton>
            </Tooltip>
            <Tooltip title="Send Notifications">
            <IconButton aria-label="Send Notifications" color="inherit" onClick={handleSendNotificationClick}>
               {props.isLoggedIn ? <SendIcon/> : ''}
            </IconButton>
            </Tooltip>
            <Tooltip title="Sign In/Out">
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {props.isLoggedIn ?  <ExitToAppIcon/> : <AccountCircle/> }
            </IconButton>
            </Tooltip>
          </div>
          {/* <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div> */}
        </Toolbar>
      </AppBar>
      {/* {renderMobileMenu}
      {renderMenu} */}
      <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBxHvXyy3ewsXlYozyryNVapVGyv89byLI' }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          <AnyReactComponent
            lat={0.0}
            lng={0.0}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
      {showAddDialog ?<AddCarWashDialog carwashSuggestion={carwashSuggestion} onDialogAction={onAddCarwashDialogAction}/> :'' } 
    </div>
  );
}
const mapStateToProps = function(state){
  return {
    isLoggingIn:state.login.isLoggingIn,
    isLoggedIn:state.login.isLoggedIn,
    loginErrMsg : state.login.loginErrorMsg
  }
}

const mapDispatchToProps = dispatch => 
  bindActionCreators (
    {
      performSignIn:LoginActions.LoginUser,
      performLogout:LoginActions.LogoutUser,
    },
    dispatch,
);

export default connect(mapStateToProps,mapDispatchToProps)(MapHome)