import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {createRootReducer,configureStore} from './reducers'
import { ConnectedRouter } from 'connected-react-router';
import {history} from './reducers'
import Routes from './Routes'
import { Provider } from 'react-redux';
const store = configureStore();


ReactDOM.render(
    <Provider store={store}>
        <Routes/>
    </Provider>,
     document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
