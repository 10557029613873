import * as Actions from '../actions/actionTypes'
import { stat } from 'fs';

const iniitalState = {
  carwashAddError : false,
  carwashAddErrorMsg : '',
  isCarwashAddError : false,
  isAddingCarwash : false,
  hasAddedCarwash : false,
  isGeocoding:false,
  geoCodeError:null,
  geoCodeResult:null,
  geoCodeCompleted:false,

  //edit suggestions
  editSuggestionRetrieveInProgress:false,
  editSuggestionError:null,
  editSuggestions:null,
  //add suggestions
  addSuggestionRetrieveInProgress:false,
  addSuggestionError:null,
  addSuggestions:null,
  //delete add suggestion
  addSuggestionDeleteInProgress:false,
  addSuggestionDeleteError:null,
};

const CarwashReducer = (state = iniitalState, action) => {
  switch(action.type){
    case Actions.ADD_CARWASH:
    {
       return {
         ...state,
         isAddingCarwash : true,
         hasAddedCarwash : false,
         isCarwashAddError : false,
       }
    }
    case Actions.ADD_CARWASH_REQUEST:{
      return {
        ...state,
        isAddingCarwash : true,
      }
    }
    case Actions.ADD_CARWASH_RESPONSE:{
      console.log('car wash add response is called ',action);
      var addResponse = action.result;
      if(addResponse) {
        console.log('add response is ',addResponse);
        var response = addResponse.response;
        console.log('response is ',response);
        if(response && response.status == 'success'){
          return {
            ...state,
            isAddingCarwash : false,
            hasAddedCarwash : true,
            isCarwashAddError : false,
            carwashAddErrorMsg : null,
          }
        }
        else {
          //success
          return {
            ...state,
            isAddingCarwash : false,
            hasAddedCarwash : false,
            isCarwashAddError : true,
            carwashAddErrorMsg : response.errMsg ? response.errMsg : 'Unexpected error while adding car wash',
          }
        }
      }
    }
    case Actions.ADD_CARWASH_ERROR:{
      var response = action.result;
      return {
        ...state,
        isAddingCarwash : false,
        hasAddedCarwash : false,
        isCarwashAddError : true,
        carwashAddErrorMsg : response.errMsg ? response.errMsg : 'Unexpected error while adding car wash',
      }
    }
    case Actions.RESET_CAR_WASH_ADD_STATES:{
      return {
        ...state,
        isAddingCarwash : false,
        hasAddedCarwash : false,
        isCarwashAddError : false,
        carwashAddErrorMsg : null,
        isGeocoding : false,
        geoCodeError : errMsg,
        geoCodeResult : errMsg ? '' : data,
      }
    }

    case Actions.PERFORM_GEOCODE_REQUEST:{
      return {
        ...state,
        isGeocoding : true,
        geoCodeError : null,
        geoCodeResult : null,
      }
    }
    case Actions.PERFORM_GEOCODE_RESPONSE:
    case Actions.PERFORM_GEOCODE_ERROR:{
      console.log('action coming in for geocode response ',action);
      var data = action.result ? action.result : action.georesult ? action.georesult : {};
      var errMsg =  null;
      if(data.status == 'success'){
        data = data.coordinates;
      }
      else {
        errMsg = data.errMsg ? data.errMsg : 'Unable to locate the address';
      }
      console.log('result coming in is ',data);
      return {
        ...state,
        isGeocoding : false,
        geoCodeError : errMsg,
        geoCodeResult : errMsg ? '' : data,
      }
    }
    case Actions.RESET_GEOCODE_STATE:{
      return {
        ...state,
        isGeocoding : false,
        geoCodeError : null,
        geoCodeResult : null,
      }
    }
    case Actions.PERFORM_GEOCODE_REQUEST:{
      return {
        ...state,
        isGeocoding : true,
        geoCodeError : null,
        geoCodeResult : null,
      }
    }
    case Actions.RETRIEVE_EDIT_SUGGESTIONS_REQUEST:{
      return {
        ...state,
        editSuggestionRetrieveInProgress : true,
        editSuggestionError : null,
        editSuggestions : null,
      }
    }
    case Actions.RETRIEVE_EDIT_SUGGESTIONS_RESPONSE:
    case Actions.RETRIEVE_EDIT_SUGGESTIONS_ERROR:{
      console.log('action coming in for edit suggestion response ',action);
      let data = action.result ? action.result.response : {};
      let errMsg =  null;
      let editSuggestions = null;
      if(data.status == 'success'){
        editSuggestions = data.records;
        console.log('Suggestions retrieved and set as ',editSuggestions);
      }
      else {
        editSuggestions = null;
        errMsg = data.errMsg ? data.errMsg : 'Unable to retrieve carwash edits';
      }
      console.log('result coming in is ',data);
      return {
        ...state,
        editSuggestionRetrieveInProgress : false,
        editSuggestionError : errMsg,
        editSuggestions,
      }
    }
    case Actions.RETRIEVE_ADD_SUGGESTIONS_REQUEST:{
      return {
        ...state,
        addSuggestionRetrieveInProgress : true,
        addSuggestionError : null,
        addSuggestions : null,
      }
    }
    case Actions.RETRIEVE_ADD_SUGGESTIONS_RESPONSE:
    case Actions.RETRIEVE_ADD_SUGGESTIONS_ERROR:{
      console.log('action coming in for add suggestion response ',action);
      let data = action.result ? action.result.response : {};
      let errMsg =  null;
      let addSuggestions = null;
      if(data.status == 'success'){
        addSuggestions = data.records;
        console.log('Suggestions retrieved for add and set as ',addSuggestions);
      }
      else {
        addSuggestions = null;
        errMsg = data.errMsg ? data.errMsg : 'Unable to retrieve carwash add suggestions';
      }
      console.log('result coming in is ',data);
      return {
        ...state,
        addSuggestionRetrieveInProgress : false,
        addSuggestionError : errMsg,
        addSuggestions,
      }
    }
    case Actions.DELETE_ADD_SUGGESTIONS_REQUEST:{
      console.log('delete add suggestion request ',action);
      console.log('result coming in is ',data);
      return {
        ...state,
        addSuggestionDeleteInProgress : true,
        addSuggestionDeleteError : null
      }
    }
    case Actions.DELETE_ADD_SUGGESTIONS_RESPONSE:
    case Actions.DELETE_ADD_SUGGESTIONS_ERROR:{
      console.log('delete add suggestion response ',action);
      let data = action.result ? action.result.response : {};
      let errMsg =  null;
      if(data.status == 'success'){
        console.log('Suggestion deletion successful');
      }
      else {
        errMsg = data.errMsg ? data.errMsg : 'Unable to delete carwash suggestion';
      }
      return {
        ...state,
        addSuggestionDeleteInProgress : false,
        addSuggestionDeleteError : errMsg
      }
    }
    case Actions.DELETE_ADD_SUGGESTIONS_CLEAR:
    {
      return {
        ...state,
        addSuggestionDeleteInProgress : false,
        addSuggestionDeleteError : null
      }
    }
  }
  return state;
};

export default CarwashReducer;