import {combineReducers, applyMiddleware} from 'redux';
import { createStore } from 'redux';
import {createHashHistory} from 'history'
import LoginReducer from './login'
import  CarwashReducer from './carwash'
import NotificationsReducer from './notifications'
import AppSagas from '../sagas/AppSagas'
import createSagaMiddleware from 'redux-saga'
import {routerMiddleware, connectRouter} from 'connected-react-router'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export var history =  createHashHistory();
export var appStore = null;
const router = routerMiddleware(history);

const rootPersistConfig = {
  key:'kfgroot',
  storage:storage,
}

export var persistor = null;

export function createRootReducer(history){
  return combineReducers(
    {
      router:connectRouter(history),
      login:LoginReducer,
      carwash:CarwashReducer,
      notifications:NotificationsReducer,
    }
  );
}


const sagaMiddleWare = createSagaMiddleware();

export function configureStore(){
  console.log('history value :',history);
  const prs = persistReducer(rootPersistConfig,createRootReducer(history))
  var store = createStore(prs,applyMiddleware(sagaMiddleWare));
  sagaMiddleWare.run(AppSagas);
  appStore = store;
  persistor = persistStore(store);
  return appStore;
}